import DoughnutChart from "../../charts/DoughnutChart";
import Loading from "./Loading";
import Tooltip from "@/charts/Tooltip";
import { tailwindConfig } from "@/utils/utils";

const chartData = {
  labels: ["Desktop", "Mobile", "Tablet"],
  datasets: [
    {
      label: "Orders Split",
      data: [12, 50, 38],
      backgroundColor: [
        tailwindConfig().theme.colors.indigo[500],
        tailwindConfig().theme.colors.sky[400],
        tailwindConfig().theme.colors.indigo[800],
      ],
      hoverBackgroundColor: [
        tailwindConfig().theme.colors.indigo[600],
        tailwindConfig().theme.colors.sky[500],
        tailwindConfig().theme.colors.indigo[900],
      ],
      hoverBorderColor: tailwindConfig().theme.colors.white,
    },
  ],
};

function humanize(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

export default function OrderSplit({ data, error, isLoading }) {
  if (isLoading) {
    return <Loading />;
  }

  const summaryData = data.summary;
  chartData.labels = [humanize("new_orders"), humanize("returning_orders")];
  chartData.datasets[0].data = summaryData[1].reduce((acc, row) => {
    acc[0] += parseInt(row.new_orders);
    acc[1] += parseInt(row.returning_orders);
    return acc;
  }, [0, 0]);

  return (
    <div className="flex flex-col">
      <header className="flex items-center gap-2 pb-1 mb-1 text-xs border-b border-slate-100">
        <h2 className="font-medium text-slate-800">New vs Returning Order Split</h2>

        <Tooltip size="lg">
          <div className="text-sm">
          New vs Returning Orders From Your Ecommerce Platform.
          </div>
        </Tooltip>
      </header>

      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <DoughnutChart data={chartData} width={375} height={300} />
    </div>
  );
}
