import {
  formatCurrency,
  formatCurrencyDecimal,
  formatTimes,
  hexToRGB,
  tailwindConfig,
} from "@/utils/utils";

import ButtonLayout from "./ButtonLayout";
import LineChart from "../../charts/LineChart03";
import Loading from "./Loading";
import { useState } from "react";

const chartData = {
  labels: [],
  datasets: [
    // Indigo line
    {
      label: "Current",
      data: [],
      fill: true,
      backgroundColor: `rgba(${hexToRGB(
        tailwindConfig().theme.colors.blue[500]
      )}, 0.08)`,
      borderColor: tailwindConfig().theme.colors.indigo[500],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      clip: 20,
    },
    // Gray line
    {
      label: "Previous",
      data: [],
      borderColor: tailwindConfig().theme.colors.slate[300],
      fill: false,
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.slate[300],
      clip: 20,
    },
  ],
};

export default function MultiChart({ data, isLoading }) {
  const [show, setShow] = useState("mkt_spend");

  if (isLoading) {
    return <Loading />;
  }

  const handleMarketingSpend = () => {
    setShow("mkt_spend");
  };

  const handleMarketingSales = () => {
    setShow("mkt_sales");
  };

  const handleRoas = () => {
    setShow("roas");
  };

  const handleMer = () => {
    setShow("mer");
  };

  const handleCac = () => {
    setShow("cac");
  };

  const summaryData = data.summary;
  const labels = [];

  const currMarketingSpendDataset = [];
  const prevMarketingSpendDataset = [];
  let currMarketingSpend = 0;
  let prevMarketingSpend = 0;

  const currMarketingSalesDataset = [];
  const prevMarketingSalesDataset = [];

  let currMarketingSales = 0;
  let prevMarketingSales = 0;

  let currNewOrders = 0;
  let prevNewOrders = 0;

  let currSales = 0;
  let prevSales = 0;

  const currRoasDataset = [];
  const prevRoasDataset = [];

  const currMerDataset = [];
  const prevMerDataset = [];

  const currCacDataset = [];
  const prevCacDataset = [];

  summaryData[0].forEach((row) => {
    prevSales += parseFloat(row.sales);
    prevNewOrders += parseFloat(row.new_orders);

    const roas = Number((row.mkt_sales / row.mkt_spend).toFixed(2));
    const mer = Number((row.sales / row.mkt_spend).toFixed(2));
    const cac = Number((row.mkt_spend / row.new_orders).toFixed(2));

    prevMarketingSpendDataset.push(row.mkt_spend);
    prevMarketingSpend += parseFloat(row.mkt_spend);

    prevMarketingSalesDataset.push(row.mkt_sales);
    prevMarketingSales += parseFloat(row.mkt_sales);

    prevRoasDataset.push(roas);
    prevMerDataset.push(mer);
    prevCacDataset.push(cac);
  });

  summaryData[1].forEach((row) => {
    currSales += parseFloat(row.sales);
    currNewOrders += parseFloat(row.new_orders);

    const roas = Number((row.mkt_sales / row.mkt_spend).toFixed(2));
    const mer = Number((row.sales / row.mkt_spend).toFixed(2));
    const cac = Number((row.mkt_spend / row.new_orders).toFixed(2));

    labels.push(row.date.split("T")[0]);

    currMarketingSpendDataset.push(row.mkt_spend);
    currMarketingSpend += parseFloat(row.mkt_spend);

    currMarketingSalesDataset.push(row.mkt_sales);
    currMarketingSales += parseFloat(row.mkt_sales);

    currRoasDataset.push(roas);
    currMerDataset.push(mer);
    currCacDataset.push(cac);
  });

  const currRoas = currMarketingSales / currMarketingSpend;
  const prevRoas = prevMarketingSales / prevMarketingSpend;

  const currMer = currSales / currMarketingSpend;
  const prevMer = prevSales / prevMarketingSpend;

  const currCac = currMarketingSpend / currNewOrders;
  const prevCac = prevMarketingSpend / prevNewOrders;

  const chartedData = {
    mkt_spend: structuredClone(chartData),
    mkt_sales: structuredClone(chartData),
    roas: structuredClone(chartData),
    mer: structuredClone(chartData),
    cac: structuredClone(chartData),
  };

  chartedData.mkt_spend.labels = labels;
  chartedData.mkt_spend.datasets[0].data = currMarketingSpendDataset;
  chartedData.mkt_spend.datasets[1].data = prevMarketingSpendDataset;

  chartedData.mkt_sales.labels = labels;
  chartedData.mkt_sales.datasets[0].data = currMarketingSalesDataset;
  chartedData.mkt_sales.datasets[1].data = prevMarketingSalesDataset;

  chartedData.roas.labels = labels;
  chartedData.roas.datasets[0].data = currRoasDataset;
  chartedData.roas.datasets[1].data = prevRoasDataset;

  chartedData.mer.labels = labels;
  chartedData.mer.datasets[0].data = currMerDataset;
  chartedData.mer.datasets[1].data = prevMerDataset;

  chartedData.cac.labels = labels;
  chartedData.cac.datasets[0].data = currCacDataset;
  chartedData.cac.datasets[1].data = prevCacDataset;

  return (
    <div className="flex flex-col">
      <div className="pb-1">
        <h2 className="text-xs font-medium text-slate-800">
          Marketing Analytics
        </h2>
      </div>

      <div className="grid grid-cols-5 border divide-x border-slate-100">
        <MarketingSpendFigures
          show={show}
          current={currMarketingSpend}
          previous={prevMarketingSpend}
          onClick={handleMarketingSpend}
        />

        <MarketingSalesFigures
          show={show}
          current={currMarketingSales}
          previous={prevMarketingSales}
          onClick={handleMarketingSales}
        />

        <RoasFigures
          show={show}
          current={currRoas}
          previous={prevRoas}
          onClick={handleRoas}
        />

        <MerFigures
          show={show}
          current={currMer}
          previous={prevMer}
          onClick={handleMer}
        />

        <CacFigures
          show={show}
          current={currCac}
          previous={prevCac}
          onClick={handleCac}
        />
      </div>

      <div className="grow">
        {show === "mkt_spend" && (
          <LineChart
            data={chartedData[show]}
            width={800}
            height={300}
            fmtLabel={formatCurrency}
          />
        )}

        {show === "mkt_sales" && (
          <LineChart
            data={chartedData[show]}
            width={800}
            height={300}
            fmtLabel={formatCurrency}
          />
        )}

        {show === "roas" && (
          <LineChart
            data={chartedData[show]}
            width={800}
            height={300}
            fmtLabel={formatTimes}
          />
        )}

        {show === "mer" && (
          <LineChart
            data={chartedData[show]}
            width={800}
            height={300}
            fmtLabel={formatTimes}
          />
        )}

        {show === "cac" && (
          <LineChart
            data={chartedData[show]}
            width={800}
            height={300}
            fmtLabel={formatCurrencyDecimal}
          />
        )}
      </div>
    </div>
  );
}

function MarketingSpendFigures({ show, current, previous, onClick }) {
  const formatter = new Intl.NumberFormat("en-GB", {
    notation: "compact",
    style: "currency",
    currency: "GBP",
  });

  return (
    <ButtonLayout
      active={show === "mkt_spend"}
      title="Marketing Spend"
      tooltip="Total Cost From Your Integrated Marketing Platforms"
      onClick={onClick}
      current={current}
      previous={previous}
    >
      {formatter.format(current)}
    </ButtonLayout>
  );
}

function MarketingSalesFigures({ show, current, previous, onClick }) {
  const formatter = new Intl.NumberFormat("en-GB", {
    notation: "compact",
    style: "currency",
    currency: "GBP",
  });

  return (
    <ButtonLayout
      active={show === "mkt_sales"}
      title="Marketing Sales"
      tooltip="Total Revenue Extracted From Your Connected Marketing Platforms"
      onClick={onClick}
      current={current}
      previous={previous}
    >
      {formatter.format(current)}
    </ButtonLayout>
  );
}

function RoasFigures({ show, current, previous, onClick }) {
  return (
    <ButtonLayout
      active={show === "roas"}
      title="Digital ROAS"
      tooltip="Total Attributed Revenue / Total Marketing Spend"
      onClick={onClick}
      current={current}
      previous={previous}
    >
      {isNaN(current) ? "0" : current.toFixed(2)}x
    </ButtonLayout>
  );
}

function MerFigures({ show, current, previous, onClick }) {
  return (
    <ButtonLayout
      active={show === "mer"}
      title="MER"
      tooltip="Total Gross Revenue / Total Marketing Cost"
      onClick={onClick}
      current={current}
      previous={previous}
    >
      {isNaN(current) || !isFinite(current) ? "0" : current.toFixed(2)}x
    </ButtonLayout>
  );
}

function CacFigures({ show, current, previous, onClick }) {
  const formatter = new Intl.NumberFormat("en-GB", {
    notation: "compact",
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  });

  return (
    <ButtonLayout
      active={show === "cac"}
      title="CAC"
      tooltip="Total Marketing Cost / New Customer Orders"
      onClick={onClick}
      current={current}
      previous={previous}
      reverse={true}
    >
      {isNaN(current) ? "0" : formatter.format(current)}
    </ButtonLayout>
  );
}
