import Loading from "./Loading";
import OptsiDatatable from "../datatable";
import Tooltip from "@/charts/Tooltip";

const fmtCurr = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  maximumFractionDigits: 0,
});

const fmtNumber = new Intl.NumberFormat("en-GB", {
  maximumSignificantDigits: 3,
});

export default function Categories({ data, error, isLoading }) {
  if (isLoading) {
    return <Loading />;
  }

  const categoriesData = data.categories;
  const columns = [
    {
      name: "Category",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Sales",
      selector: (row) => Number(row.sales),
      format: (row) => fmtCurr.format(row.sales),
      center: true,
      sortable: true,
    },
    {
      name: "Inventory",
      selector: (row) => Number(row.quantity),
      format: (row) => fmtNumber.format(row.quantity),
      center: true,
      sortable: true,
    },
  ];

  return (
    <div className="flex flex-col">
      <header className="flex items-center gap-2 pb-1 mb-1 text-xs border-b border-slate-100">
        <h2 className="font-medium text-slate-800">Top Categories</h2>

        <Tooltip size="lg">
          <div className="text-sm">
            Product Sales Over The Last 28 Days. Taxes are included. Inventory
            Is Today's Inventory.
          </div>
        </Tooltip>
      </header>

      <OptsiDatatable columns={columns} data={categoriesData} />
    </div>
  );
}
