import { formatCurrency, hexToRGB, tailwindConfig } from "@/utils/utils";

import LineChart from "../../charts/LineChart01Custom";
import Loading from "./Loading";
import PercentageChange from "../percentage_change";
import Tooltip from "@/charts/Tooltip";

const chartData = {
  labels: [],
  datasets: [
    // Indigo line
    {
      label: "Current",
      data: [],
      fill: true,
      backgroundColor: `rgba(${hexToRGB(
        tailwindConfig().theme.colors.blue[500]
      )}, 0.08)`,
      borderColor: tailwindConfig().theme.colors.indigo[500],
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      clip: 20,
    },
    // Gray line
    {
      label: "Previous",
      data: [],
      borderColor: tailwindConfig().theme.colors.slate[300],
      fill: false,
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors.slate[300],
      clip: 20,
    },
  ],
};

export default function Sales({ data, error, isLoading }) {
  if (isLoading) {
    return <Loading />;
  }

  const summaryData = data.summary;
  const labels = [];
  const currDataset = [];
  const prevDataset = [];
  let currSales = 0;
  let prevSales = 0;

  summaryData[0].forEach((row, index) => {
    prevDataset.push(row.sales);
    prevSales += parseFloat(row.sales);
  });

  summaryData[1].forEach((row, index) => {
    labels.push(row.date.split("T")[0]);
    currDataset.push(row.sales);
    currSales += parseFloat(row.sales);
  });

  chartData.labels = labels;
  chartData.datasets[0].data = currDataset;
  chartData.datasets[1].data = prevDataset;

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  });

  return (
    <div className="flex flex-col">
      <header className="flex items-center justify-between gap-2 text-xs">
        <div className="flex gap-2">
          <h2 className="font-medium text-slate-800">Sales</h2>

          <Tooltip size="lg">
            <div className="text-sm">
              Gross Sales - Discounts + Taxes + Shipping
            </div>
          </Tooltip>
        </div>

        <div className="flex items-center">
          <PercentageChange
            formatter={formatter}
            current={currSales}
            previous={prevSales}
          />
        </div>
      </header>

      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        <LineChart data={chartData} width={390} height={200} fmtLabel={formatCurrency} />
      </div>
    </div>
  );
}
