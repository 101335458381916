import "chartjs-adapter-moment";

import { ArcElement, Chart, Legend, TimeScale, Tooltip } from "chart.js";
import React, { useEffect, useRef } from "react";

import { Doughnut } from "react-chartjs-2";
// Import utilities
import { tailwindConfig } from "@/utils/utils";

Chart.register(ArcElement, TimeScale, Legend);

function DoughnutChart({ data, width, height }) {
  const legend = useRef(null);

  const options = {
    cutout: "80%",
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "nearest",
    },
    animation: {
      duration: 500,
    },
    maintainAspectRatio: false,
    resizeDelay: 200,
  };

  const plugins = [
    {
      id: "htmlLegend",
      afterUpdate(c, args, options) {
        const ul = legend.current;
        if (!ul) return;
        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove();
        }
        // Reuse the built-in legendItems generator
        const items = c.options.plugins.legend.labels.generateLabels(c);
        items.forEach((item) => {
          const li = document.createElement("li");
          li.style.margin = tailwindConfig().theme.margin[1];
          // Button element
          const button = document.createElement("button");
          button.classList.add("btn-xs");
          button.style.backgroundColor = tailwindConfig().theme.colors.white;
          button.style.borderWidth = tailwindConfig().theme.borderWidth[1];
          button.style.borderColor = tailwindConfig().theme.colors.slate[200];
          button.style.color = tailwindConfig().theme.colors.slate[500];
          button.style.boxShadow = tailwindConfig().theme.boxShadow.md;
          button.style.opacity = item.hidden ? ".3" : "";
          button.onclick = () => {
            c.toggleDataVisibility(item.index, !item.index);
            c.update();
          };
          // Color box
          const box = document.createElement("span");
          box.style.display = "block";
          box.style.width = tailwindConfig().theme.width[2];
          box.style.height = tailwindConfig().theme.height[2];
          box.style.backgroundColor = item.fillStyle;
          box.style.borderRadius = tailwindConfig().theme.borderRadius.sm;
          box.style.marginRight = tailwindConfig().theme.margin[1];
          box.style.pointerEvents = "none";
          // Label
          const label = document.createElement("span");
          label.style.display = "flex";
          label.style.alignItems = "center";
          const labelText = document.createTextNode(item.text);
          label.appendChild(labelText);
          li.appendChild(button);
          button.appendChild(box);
          button.appendChild(label);
          ul.appendChild(li);
        });
      },
    },
  ];

  return (
    <div className="flex flex-col justify-center grow">
      <div>
        <Doughnut
          data={data}
          options={options}
          plugins={plugins}
          width={width}
          height={height}
        />
      </div>
      <div className="px-2 pt-2 pb-4">
        <ul ref={legend} className="flex flex-wrap justify-center -m-1"></ul>
      </div>
    </div>
  );
}

export default DoughnutChart;
