import ContentLoader from "react-content-loader";

export default function Loading() {
    return (
      <ContentLoader height={185} width="">
        <rect x="0" y="0" rx="5" width="100%" height="25" />
        <rect x="0" y="30" rx="5" width="100%" height="155" />
      </ContentLoader>
    );
  }
  