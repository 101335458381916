import { fetcher, setupQuery } from "@/utils/utils";

import AOV from "@/components/dashboard/aov";
import Categories from "@/components/dashboard/categories";
import { DateRangePickerV2 } from "@/components/datepicker_v2";
import Head from "next/head";
import Layout from "@/components/layout";
import MultiChart from "@/components/dashboard/multi_chart";
import OrderSplit from "@/components/dashboard/order_split";
import Orders from "@/components/dashboard/orders";
import Products from "@/components/dashboard/products";
import Sales from "@/components/dashboard/sales";
import moment from "moment";
import useSWR from "swr";
import { useSessionstorageState } from "rooks";

export default function Home() {
  const [dates, setDates] = useSessionstorageState("dates", setupQuery());

  const params = new URLSearchParams({
    from_start: moment(dates.from_start).format("YYYY-MM-DD"),
    from_end: moment(dates.from_end).format("YYYY-MM-DD"),
    to_start: moment(dates.to_start).format("YYYY-MM-DD"),
    to_end: moment(dates.to_end).format("YYYY-MM-DD"),
  });

  const url = "/api/dashboard?" + params.toString();
  const { data, error, isLoading } = useSWR(url, fetcher);

  return (
    <Layout>
      <Head>
        <title>Optsi</title>
      </Head>

      <div className="flex justify-between gap-2">
        <div className="flex-1 p-2 text-sm bg-white rounded shadow">
          Dashboard
        </div>

        <DateRangePickerV2 onChange={setDates} />
      </div>

      <main className="grid grid-cols-6 gap-2 my-2">
        <div className="col-span-2 p-2 bg-white rounded-lg shadow">
          <Sales data={data} error={error} isLoading={isLoading} />
        </div>

        <div className="col-span-2 p-2 bg-white rounded-lg shadow">
          <Orders data={data} error={error} isLoading={isLoading} />
        </div>

        <div className="col-span-2 p-2 bg-white rounded-lg shadow">
          <AOV data={data} error={error} isLoading={isLoading} />
        </div>

        <div className="col-span-4 p-2 bg-white rounded-lg shadow">
          <MultiChart data={data} error={error} isLoading={isLoading} />
        </div>

        <div className="col-span-2 p-2 bg-white rounded-lg shadow">
          <OrderSplit data={data} error={error} isLoading={isLoading} />
        </div>

        <div className="col-span-2 p-2 bg-white rounded-lg shadow">
          <Categories data={data} error={error} isLoading={isLoading} />
        </div>

        <div className="col-span-4 p-2 bg-white rounded-lg shadow">
          <Products data={data} error={error} isLoading={isLoading} />
        </div>
      </main>
    </Layout>
  );
}
