import Loading from "./Loading";
import OptsiDatatable from "../datatable";
import Tooltip from "@/charts/Tooltip";

const fmtNumber = new Intl.NumberFormat("en-GB", {
  maximumSignificantDigits: 3,
});

const fmtCurr = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  maximumFractionDigits: 0,
});

export default function Products({ data, error, isLoading }) {
  if (isLoading) {
    return <Loading />;
  }

  const CustomCell = (row) => (
    <div className="py-2 truncate whitespace-nowrap">
      <div className="flex items-center">
        <div className="mr-2 overflow-hidden rounded-lg shrink-0 sm:mr-3">
          <img src={row.row.image} width={36} height={36} />
        </div>
        <div className="text-slate-800">{row.row.title}</div>
      </div>
    </div>
  );

  const productsData = data.products;
  const columns = [
    {
      name: "Product",
      selector: (row) => row.row.name,
      sortable: true,
      cell: (row) => <CustomCell row={row} />,
      width: "60%",
    },
    {
      name: "Category",
      selector: (row) => row.type,
      center: true,
      sortable: true,
    },
    {
      name: "Sales",
      selector: (row) => Number(row.sales),
      format: (row) => fmtCurr.format(row.sales),
      center: true,
      sortable: true,
    },
    {
      name: "Inventory",
      selector: (row) => Number(row.quantity),
      format: (row) => fmtNumber.format(row.quantity),
      center: true,
      sortable: true,
    },
  ];

  return (
    <div className="flex flex-col">
      <header className="flex items-center gap-2 pb-1 mb-1 text-xs border-b border-slate-100">
        <h2 className="font-medium text-slate-800">Top Products</h2>

        <Tooltip size="lg">
          <div className="text-sm">
            Product Sales Over The Last 28 Days. Taxes are included. Inventory
            Is Today's Inventory.
          </div>
        </Tooltip>
      </header>

      <OptsiDatatable columns={columns} data={productsData} />
    </div>
  );
}
