import Tooltip from "@/charts/Tooltip";
import classNames from "classnames";

export default function ButtonLayout({
  children,
  active,
  title,
  tooltip,
  current,
  previous,
  reverse,
  onClick,
}) {
  const classes = classNames("flex flex-col items-center justify-center py-2", {
    "bg-purple-50": active,
  });

  return (
    <div className={classes}>
      <button className="inline-block pb-1" onClick={onClick} title={current}>
        <div className="text-xs text-slate-500">{title}</div>
        <div className="flex items-center justify-center w-full">
          <div className="mr-2 text-xl font-medium text-slate-800">
            {children}
          </div>
          <PercentageChange
            current={current}
            previous={previous}
            reverse={reverse}
          />
        </div>
      </button>

      <Tooltip size="lg">
        <div className="text-sm">{tooltip}</div>
      </Tooltip>
    </div>
  );
}

function PercentageChange({ current, previous, reverse }) {
  if (previous === 0) return null;
  if (isNaN(current) || isNaN(previous)) return null;
  if (!isFinite(current) || !isFinite(previous)) return null;

  const pctChange = (((current - previous) / previous) * 100).toFixed(0);
  let pctChangeClass;

  if (reverse) {
    pctChangeClass = classNames("text-xs font-medium", {
      "text-red-700": current - previous >= 0,
      "text-emerald-500": current - previous < 0,
    });
  } else {
    pctChangeClass = classNames("text-xs font-medium", {
      "text-red-700": current - previous <= 0,
      "text-emerald-500": current - previous > 0,
    });
  }

  return <div className={pctChangeClass}>{pctChange}%</div>;
}
